import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageConfigKeys } from '@config/app-storage/local-storage.config';
import {
  AppStorageService,
  StorageLocation,
} from '@core/app-store/app-storage.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class DeviceIdHelper {
  private appStorage = inject(AppStorageService);
  private router = inject(Router);

  // TODO: Key dài quá
  private key =
    LocalStorageConfigKeys.feature.taiChinhThuHocPhi.thuHocPhi.caiDat.mayTram
      .value;

  getDeviceId() {
    return this.appStorage.getItem(this.key, {
      location: StorageLocation.LOCAL_STORAGE,
    });
  }

  async alertRequired() {
    const settingRoute = '/trang-chu-cai-dat';
    if (!this.getDeviceId() && this.router.url != settingRoute) {
      const res = await Swal.fire({
        allowOutsideClick: false,
        allowEscapeKey : false,
        showCancelButton: false,
        icon: 'warning',
        title: 'Cảnh báo thiết lập cấu hình',
        text: 'Ứng dụng chưa được thiết lập tên máy trạm! Vui lòng thiết lập tên máy trạm để tiếp tục sử dụng ứng dụng!',
        confirmButtonText: 'Đến cài đặt',
      });
      if (res.isConfirmed) {
        this.router.navigate([settingRoute]);
      }
    }
    return Promise.resolve();
  }
}
